import { setupAccountInterceptor } from "./setupInterceptors";
import { useUserStore } from "../stores/user";
import { useAccountStore } from "@/stores/account";
import { UserRoles } from "@/misc/constants";

const IS_LOCAL_HOST = location.host.includes("localhost");
let TOKEN = "";

export class AuthService {
  static getBearer() {
    const accessToken = AuthService.getAccessToken();
    return `Bearer ${accessToken}`;
  }

  static getAccessToken() {
    return IS_LOCAL_HOST ? localStorage.getItem("accessToken") : TOKEN;
  }

  static setAccessToken(accessToken) {
    if (IS_LOCAL_HOST) {
      localStorage.setItem("accessToken", accessToken);
    } else {
      TOKEN = accessToken;
    }
  }

  static userRoleCheck() {
    const userStore = useUserStore();

    const memberships = userStore.memberships;

    // set Admin role for Super Admin
    if (userStore.user.isAdmin) {
      userStore.setCurrentRole(UserRoles.admin);
      localStorage.setItem("role", UserRoles.admin);
      return;
    }

    const localStorageRole = localStorage.getItem("role");
    const isRoleMatch = memberships.some(mb => mb.role === localStorageRole);

    // we set local storage role if it exist in users account memeberships
    // in other case, we grab first user role as default
    if (isRoleMatch) userStore.setCurrentRole(localStorageRole);
    else {
      const firstMembershipRole = memberships[0].role;
      userStore.setCurrentRole(firstMembershipRole);
      localStorage.setItem("role", firstMembershipRole);
    }
  }

  static async fetchUserMemberships() {
    const accountStore = useAccountStore();
    const userStore = useUserStore();
    await userStore.getCurrentUser();
    const accounts = await userStore.getUserAccounts();

    const localAccountId = localStorage.getItem("accountId");
    const matchedAccount = accounts.find(acc => acc._id === localAccountId);
    let accountIdToSet = accounts[0]?._id;

    if (localAccountId && matchedAccount) accountIdToSet = localAccountId;

    userStore.setCurrentAccountId(accountIdToSet);
    accountStore.setCurrentAccount(matchedAccount || accounts.find(acc => acc?._id === accountIdToSet));

    await userStore.getUserMemberships(accountIdToSet);
    this.userRoleCheck();

    setupAccountInterceptor({ accountId: accountIdToSet });
  }

  static _setAuthData({ accessToken } = {}) {
    AuthService.setAccessToken(accessToken);
  }

  static _resetAuthData() {
    const userStore = useUserStore();
    // reset userData in store
    userStore.setUser(null);
    // reset tokens
    AuthService.setAccessToken("");
  }
}
