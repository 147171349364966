import { useUserStore } from "@/stores/user";
import { AuthService } from "@/services/auth.service";
import { getInstance } from "@/services/auth/auth0wrapper";

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title);
  if (pageTitle) window.document.title = pageTitle.meta.title;
  next();
}

export function auth0Guard(to, from, next) {
  const auth0Service = getInstance();
  const userStore = useUserStore();
  const userId = userStore.user?._id;

  if (auth0Service.isAuthenticated) {
    const authHeader = AuthService.getBearer();

    const [bearer, token] = authHeader.split(' ');
    if (bearer !== 'Bearer' || !token) return false;
    const parsedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());

    const currentTimeSeconds = Math.round(new Date().getTime() / 1000);
    const tokenExpirationTimeSeconds = parsedToken.exp;

    if (currentTimeSeconds >= tokenExpirationTimeSeconds) {
      return auth0Service.loginWithRedirect();
    }
  }


  if (userId) {
    return next();
  }

  const fn = async () => {
    if (auth0Service.isAuthenticated) {
      await AuthService.fetchUserMemberships();

      next();
    } else {
      if (to.query.error === "unauthorized" && to.query.error_description === "user is blocked") {
        alert("We're sorry you're having trouble logging in. Please contact your organisation's admin, or reach out to our support team at support@audit-angel.co.uk for assistance.");
      }

      return auth0Service.loginWithRedirect();
    }
  };

  if (!auth0Service.loading) {
    return fn();
  }

  auth0Service.$watch("loading", (loading) => {
    if (!loading) {
      return fn();
    }
  });
}
