import axios from "axios";
import { AuthService } from "@/services/auth.service";
import { getInstance } from "@/services/auth/auth0wrapper";

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setup = () => {
  axiosInstance.interceptors.request.use(
    (request) => {
      request.headers.Authorization = AuthService.getBearer();
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 403) {
        const auth0Service = getInstance();
        auth0Service.loginWithRedirect();
      }
      return Promise.reject(error);
    }
  );
};

export const setupAccountInterceptor = ({ accountId }) => {
  axiosInstance.interceptors.request.use((request) => {
    request.headers["x-account-id"] = accountId;
    return request;
  });
};
