import { defineStore } from "pinia";
import { getCurrentUser, getUserAccounts, getUserMemberships } from "../services/users";
import { UserRoles } from "../misc/constants";

export const useUserStore = defineStore("user", {
  state() {
    return {
      memberships: [],
      user: null,
      accounts: [],
      currentAccountId: null,
      currentRole: null,
    };
  },
  actions: {
    setUser(user) {
      this.user = user || null;
    },
    setCurrentAccountId(accountId) {
      this.currentAccountId = accountId;
    },
    async getCurrentUser() {
      const user = await getCurrentUser();
      this.user = user;
    },
    async getUserAccounts() {
      this.accounts = await getUserAccounts(this.user._id);
      return this.accounts;
    },
    async getUserMemberships(accountId) {
      this.memberships = await getUserMemberships(this.user._id, accountId);
    },
    setCurrentRole(role) {
      this.currentRole = role;
    }
  },
  getters: {
    currentAccount: (state) => {
      return state.accounts.find(({ _id }) => _id === state.currentAccountId);
    },
    isSuperAdmin: (state) => Boolean(state.user?.isAdmin),
    isAdmin: (state) => state.currentRole === UserRoles.admin,
    isAuditor: (state) => state.currentRole === UserRoles.auditor,
    isClinician: (state) => state.currentRole === UserRoles.doctor,
    isManager: (state) => state.currentRole === UserRoles.manager,
  },
});
