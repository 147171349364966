<template>
  <section class="header-profile d-flex flex-row justify-start align-center">
    <v-avatar class="mr-3">
      <v-img :src="userProfileImage" :alt="user.name" />
    </v-avatar>

    <div class="mb-auto mt-auto mr-2 d-flex flex-column">
      <b>{{ userLogin }}</b>
      <p class="text-body-2">{{ currentRole }}</p>
    </div>

    <v-menu v-model="isMenuActive" offset-y>
      <template v-slot:activator="{ on, attributes }">
        <v-icon v-bind="attributes" color="black" v-on="on">
          {{ menuIcon }}
        </v-icon>
      </template>

      <v-list>
        <v-list-item
          v-for="item in userActions"
          :key="item.title"
          @click="item.action"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-menu v-model="isRolesMenuOpen" v-if="isRolesMenuActive" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <p
              class="mr-4 ml-4 mb-3 mt-2 text-body-1 d-flex align-center"
              v-bind="attrs"
              v-on="on"
            >
              Switch Role <v-icon color="black">{{ rolesIcon }}</v-icon>
            </p>
          </template>
          <v-list>
            <v-list-item-group :value="selectedRoleIndex" color="primary">
              <v-list-item
                v-for="membership in memberships"
                :key="membership._id"
              >
                <v-list-item-title @click="switchUserRole(membership)">{{
                  membership.role
                }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
  </section>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user";
import DefaultAvatar from "@/assets/unknown-user.png";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "HeaderProfile",

  data: () => ({
    isMenuActive: false,
    isRolesMenuOpen: false,
  }),

  computed: {
    ...mapState(useUserStore, ["user", "memberships", "currentRole"]),
    userActions() {
      const logOutItem = {
        title: "Log Out",
        action: () => {
          this.$auth.logout();
        },
      };

      return [logOutItem];
    },
    userLogin() {
      return `${this.user.name}`;
    },
    userProfileImage() {
      return DefaultAvatar;
    },
    menuIcon() {
      return this.isMenuActive ? "mdi-menu-up" : "mdi-menu-down";
    },
    rolesIcon() {
      return this.isRolesMenuOpen ? "mdi-menu-up" : "mdi-menu-down";
    },
    isRolesMenuActive() {
      return this.memberships.length > 1;
    },
    selectedRoleIndex() {
      return this.memberships.findIndex((mb) => mb.role === this.currentRole);
    },
  },

  methods: {
    ...mapActions(useAuthStore, ["logout"]),
    ...mapActions(useUserStore, ["setCurrentRole"]),
    switchUserRole(membership) {
      localStorage.setItem("role", membership.role);
      if (this.$router.currentRoute.path !== "/") window.location.replace("/");
      else this.$router.go();
    },
  },
};
</script>
