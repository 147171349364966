<template>
  <v-dialog
    :value="value"
    width="616"
    style="z-index: 20001"
    persistent
    @click:outside="onCancel"
  >
    <v-card class="user-edit-modal pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0">
        Create Services
      </v-card-title>

      <v-form class="mt-5" @submit.prevent="onSubmit">
        <v-text-field
          :value="formData.services"
          :error-messages="servicesErrors"
          :loading="loading"
          type="text"
          clearable
          label="Services"
          outlined
          placeholder="Service1, Service2"
          class="mb-2"
          @input="onTextFieldsInput($event, 'services')"
          @blur="$v.formData.services.$touch()"
        />
      </v-form>

      <v-card-actions class="pa-0 mt-4 d-flex justify-end">
        <v-btn color="primary" :disabled="loading" text @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          :disabled="$v.$invalid || !$v.$dirty"
          :loading="loading"
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="onSubmit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { createMultipleServices } from "@/services/clinicalServices";

export default {
  name: "MultipleServiceModal",

  mixins: [validationMixin],

  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      loading: false,
      formData: {},
    };
  },

  validations: {
    formData: {
      services: { required, minLength: minLength(2) },
    },
  },

  computed: {
    servicesErrors() {
      const errors = [];
      if (!this.$v.formData.services.$dirty) return errors;
      !this.$v.formData.services.required &&
        errors.push("Services is required");
      !this.$v.formData.services.minLength &&
        errors.push("Services must have a minimum 2 characters");
      return errors;
    },
  },

  watch: {
    value(newValue) {
      if (newValue) {
        this.initModalData();
      }
    },
  },

  methods: {
    async initModalData() {
      this.formData = { services: "" };
    },
    onCancel() {
      this.reset();
      this.toggleDialog();
    },
    toggleDialog() {
      this.$emit("input", !this.value);
    },
    async onSubmit() {
      try {
        this.loading = true;

        const services = this.formData.services
          ?.trim()
          ?.split(", ")
          ?.filter(Boolean);
        if (!services.length) return;

        await createMultipleServices(services);
        this.onCancel();
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Creation Failed",
          text: error.response?.data?.message || error.message,
        });
      } finally {
        this.$emit("fetchClinicalServices");
        this.loading = false;
      }
    },
    onTextFieldsInput(value, key) {
      this.formData[key] = value;
      this.$v.formData[key].$touch();
    },
    reset() {
      this.$v.$reset();
      this.formData = {};
    },
  },
};
</script>
  